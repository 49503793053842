















import { ref, defineComponent, useFetch, useRouter, useContext, onMounted, computed } from '@nuxtjs/composition-api';
import HybrisAddressForm from '@hybris/pages/AddressBook/HybrisAddressForm.vue';
import type { SubmitEventPayload } from '~/modules/customer/types/form';
import { useMyAccountNavigation } from '@theme/modules/customer/stores/myAccountNavigation';
import addressGetters from '~/integrations/hybris/src/getters/hybrisAddressesGetters';
import type { AddressForm as Form } from '@hybris/types';
import useHybrisAddresses from '@hybris/composables/useHybrisAddresses';

export default defineComponent({
  name: 'HybrisAddressEdit',
  components: {
    HybrisAddressForm,
  },
  middleware: 'is-authenticated',
  props: {
    addressId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const { localeRoute, localePath, i18n } = useContext();
    const store = useMyAccountNavigation();
    const address = ref<Form>(null);
    const totalAddresses = ref<number>(0);
    const { getAddress, getAddressBook, updateAddress, loading: isAddressLoading } = useHybrisAddresses();
    const isDefaultShippingAddressChanged = ref<boolean>(false);

    useFetch(async () => {
      const [addressBook, addressesData] = await Promise.all([
        getAddressBook(),
        getAddress({ addressCode: props.addressId })
      ]);
      totalAddresses.value = addressGetters.getTotal(addressBook);
      address.value = addressesData.addressForm;
    });

    const onDefaultShippingChange = (isChanged: boolean) => {
      isDefaultShippingAddressChanged.value = isChanged;
    }

    const update = async ({ form }: SubmitEventPayload<Form>) => {
      await updateAddress({
        addressForm: { ...form },
        isDefaultShippingChanged: form.isDefaultShippingAddress,
      });
      await router.push(localeRoute({ name: 'customer-my-address-book' }));
    };

    onMounted(() => {
      store.setBreadcrumbs([
        {
          text: i18n.t('Address book') as string,
          link: localePath({ name: 'customer-my-address-book' }),
        },
        {
          text: i18n.t('Edit Address') as string,
          link: localePath({ name: 'customer-my-address-book-edit' }),
        },
      ])
    });

    return {
      address,
      update,
      isAddressLoading,
      onDefaultShippingChange,
      isSingleAddress: computed(() => totalAddresses.value === 1),
      isDefaultShipping: computed(() => addressGetters.isDefaultShipping(address.value)),
    };
  },
});

