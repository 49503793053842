







































































































































































































































































































































import { SfButton, SfCheckbox, SfComponentSelect } from '@storefront-ui/vue';
import { ValidationProvider } from 'vee-validate';
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
  PropType,
} from '@nuxtjs/composition-api';
import { CountryCodeEnum } from '~/composables';
import FormElement from '@theme/components/form/FormElement.vue';
import TextField from '@theme/components/form/TextField.vue';
import PhoneInput from '@theme/components/form/PhoneInput.vue';
import { useAddressLookup } from '~/integrations/experian/composables/useAddressLookup'
import { AddressInterface } from '~/integrations/experian/src/types';
import PostcodeLookup from '@theme/components/form/PostcodeLookup.vue';
import type { AddressForm, Region, RegionsMap, Country, Title } from '@hybris/types';
import useHybrisAddresses from '@hybris/composables/useHybrisAddresses';
import addressGetters from '@hybris/getters/hybrisAddressesGetters';

export default defineComponent({
  name: 'HybrisAddressForm',
  components: {
    SfButton,
    SfCheckbox,
    ValidationProvider,
    TextField,
    PhoneInput,
    SfComponentSelect,
    FormElement,
    PostcodeLookup,
  },
  props: {
    address: {
      type: Object as PropType<AddressForm>,
      default: () => ({
        id: '',
        firstName: '',
        lastName: '',
        line1: '',
        line2: '',
        phone: '',
        countryIso: '',
        townCity: '',
        postcode: '',
        titleCode: '',
        regionIso: '',
        cellphone: '',
        isDefaultBillingAddress: false,
        isDefaultContactAddress: false,
        isDefaultShippingAddress: false,
        isDefaultAddress: false,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDefaultAddressDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { addAddressDetails } = useHybrisAddresses();

    const form = ref<AddressForm | null>(null);
    const countries = ref<Country[]>([]);
    const regions = ref<RegionsMap | null>(null);
    const titles = ref<Title[]>([]);
    const isManualAddressInput = ref<boolean>(false);
    const isAltTelephoneAvailable = ref<boolean>(false);

    const isUkSelected = computed<boolean>(() => form.value.countryIso === 'GB');
    const regionsList = computed<Region[]>(() => addressGetters.getCountryRegions(
      form.value.countryIso,
      regions.value,
    ));

    const updateTitle = (params: string) => {
      form.value.titleCode = params;
    };

    watch(() => props.address, () => {
      form.value = {
        id: props.address.id,
        firstName: props.address.firstName,
        lastName: props.address.lastName,
        line1: props.address.line1,
        line2: props.address.line2,
        phone: props.address.phone,
        countryIso: props.address.countryIso,
        townCity: props.address.townCity,
        postcode: props.address.postcode,
        titleCode: props.address.titleCode,
        regionIso: props.address.regionIso,
        cellphone: props.address.cellphone,
        isDefaultBillingAddress: props.address.isDefaultBillingAddress || false,
        isDefaultContactAddress: props.address.isDefaultContactAddress || false,
        isDefaultShippingAddress: props.address.isDefaultShippingAddress || false,
        isDefaultAddress: (props.address.isDefaultBillingAddress && props.address.isDefaultShippingAddress) || false,
      } as AddressForm;

      if (form.value.line1) {
        isManualAddressInput.value = true;
      }
    }, { immediate: true });

    // Preselect checkboxes if the customer creates the first address
    watch(() => props.isDefaultAddressDisabled, () => {
      if (props.isDefaultAddressDisabled) {
        form.value.isDefaultBillingAddress = true;
        form.value.isDefaultShippingAddress = true;
      } else {
        form.value.isDefaultBillingAddress = false;
        form.value.isDefaultShippingAddress = false;
      }
    })

    const submitForm = () => {
      emit('submit', {
        form: form.value
      });
    };

    const onInvalidForm = () => {
      isManualAddressInput.value = !isManualAddressInput.value && isUkSelected.value;
    }

    onBeforeMount(async () => {
      const addressDetails = await addAddressDetails();

      titles.value = addressGetters.getTitles(addressDetails);
      countries.value = addressGetters.getCountriesList(addressDetails);
      regions.value = addressDetails?.countryIsoRegionsMap || null;
    });

    onMounted(() => {
      if (!props.address.countryIso) {
        form.value.countryIso = 'GB' as CountryCodeEnum;
      }
    });

    /**
     * Postcode lookup - Start
     */
    const { getAddressStreet, getFormattedAddress } = useAddressLookup();
    const postcodeLookup = ref(null);
    const isTypingPostcode = ref<boolean>(false);
    const addressError = ref<string>('');

    /**
     * Handle blur outside postcode suggestions
     * @param {FocusEvent} event
     */
    const handleBlurOutsideSuggestions = (event) => {
      if (!postcodeLookup.value?.$el?.contains(event.relatedTarget)) {
        isTypingPostcode.value = false;
      }
    };

    /**
     * Select address from postcode lookup
     * @param {AddressInterface} address
     */
    const selectAddress = async ({ format }: AddressInterface) => {
      const { data: formattedAddress } = await getFormattedAddress(format);

      form.value = {
        ...form.value,
        postcode: formattedAddress.postal_code,
        line1: getAddressStreet(formattedAddress),
        townCity: formattedAddress.locality,
        // if it has 'address_line_2' it means that 'address_line_1' is apartment
        ...(formattedAddress?.address_line_2 && {
          line2: formattedAddress?.address_line_1
        })
      };

      addressError.value = '';
      isManualAddressInput.value = true;
      isTypingPostcode.value = false;
    };

    const postcodeLookupData = {
      postcodeLookup,
      isTypingPostcode,
      addressError,
      handleBlurOutsideSuggestions,
      selectAddress,
    };
    /**
     * Postcode lookup - End
     */

    return {
      form,
      submitForm,
      countries,
      isManualAddressInput,
      titles,
      updateTitle,
      isUkSelected,
      isAltTelephoneAvailable,
      ...postcodeLookupData,
      onInvalidForm,
      regionsList,
    };
  },
});
